﻿@import "../../../../../Common/src/scss/components/react/AddressForm.scss";

.AddressForm {

}

.CustomerAddressesGrid {
	.btn {
		padding: 7px 28px;
	}
}
