@import '../../../Common/src/scss/blocks/_newslettersubscriptionblock.scss';

.newsletter__subscription {
    background-color: var(--primary);

    .alert-custom,
    .alert-primary {
        border-color: $preston-green !important;
    }

    button[type='submit'] {
        max-width: 250px !important;
        background-color: var(--secondary);
        color: $dark;

        @include media-breakpoint-up('md') {
            padding-top: 13px !important;
            padding-bottom: 13px !important;
        }
    }
}
