.article__card {
    .card-img-top {
        filter: brightness(50%) !important;
    }

    .card-title {
        font-family: var(--primary-font) !important;
        font-weight: 600 !important;
        text-transform: uppercase !important;
        line-height: 0.93em;
        @include font-size(24px, true);
    }

    .card__footer-link {
        @include font-size(14px, true);
        font-weight: 600 !important;
        color: $white !important;

        i {
            color: var(--secondary);
        }
    }

    .subtitle {
        font-weight: 600 !important;
    }
}

.article__cardrow {
    .article__card {
        min-height: 250px;
        height: auto !important;
    }
    .card {
        .subtitle {
            font-size: 1rem;
            font-family: var(--primary-font);
            text-transform: uppercase;
        }
        .card__footer-link {
            position: unset;
        }
    }
}

.articlePage-bodycopy {
    overflow-wrap: break-word;
}
