﻿.social {
    &__link {
        line-height: 35px;
        display: inline-flex;
        align-items: center;
        &:not(:first-child) {
            margin-left: 8px;
        }
    }

    &__icon {
        font-size: 30px !important;
        color: $white;
    }
}

@include media-breakpoint-down('xs') {
    .social {
        &__link {
            &:not(:first-child) {
                margin-left: 1px;
            }
        }
    }
}

.social-icons {
    margin-bottom: 0;
    margin-left: -5px;

    & > li {
        display: inline-block;
        padding: 0 2px 5px 2px;

        & > a {
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 28px;
            background-color: $tertiary-dark;
            border-radius: 3px;
            text-align: center;
            vertical-align: middle;
            color: $white;
            text-decoration: none;

            i {
                font-size: 14px;
            }
        }

        & > a:active,
        & > a:hover,
        & > a:focus {
            background-color: $gray-tertiary;
            text-decoration: none;
            outline: 0;
        }
    }
}
