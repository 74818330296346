﻿@import '../../../../../Common/src/scss/components/react/CartTable.scss';

.CartTable {
    @include media-breakpoint-down(md) {
        span {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}
