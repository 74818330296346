.sectionnavblock {
    .navigation__section {
        background-color: $gray-medium !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0.5em !important;
        padding-bottom: 0.5em !important;

        .nav-item {
            .nav-link {
                color: $black !important;
                font-weight: 600 !important;
                text-transform: uppercase !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                line-height: normal !important;
                padding-top: 0.1em !important;
                padding-bottom: 0.1em !important;

                transition: background-color 0.25s;

                &.active,
                &:hover {
                    background-color: var(--primary);
                }
            }
        }
    }
}
