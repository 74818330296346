.ProductChildTable {
    thead {
        th {
            font-weight: 700;
        }
    }
    tbody {
        font-family: var(--tertiary-font);
        td {
            @include font-size(14px);
        }
    }
}
