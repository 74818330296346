﻿@import '../../../../Common/src/scss/core/mast.scss';

.mast__section {
    background-color: $black;
    opacity: 0.4;
    z-index: 1;

    & > * {
        z-index: 1;
    }
}
