﻿@import '../../../../../Common/src/scss/components/react/ProductBadge.scss';

.ProductBadge {
    @include font-size(14px);
    .badge {
        padding: 0.75rem 1.5rem;
        line-height: calc(1em - 1px);
        background: $preston-green;
        color: $white;
        font-family: var(--tertiary-font);
    }
}
