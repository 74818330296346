.dropdown-menu {
    background-color: $gray-ghost !important;
    color: $gray-dark !important;

    input[type='checkbox'],
    input[type='radio'] {
        &.light {
            border-color: $gray-dark !important;
        }

        &::before {
            background-color: $gray-dark !important;
        }
    }
}

.dropdown__drawer {
    background-color: $gray-ghost;

    a {
        color: $gray-dark;

        .dropdown-item {
            color: $black;
            background-color: $gray-medium;
            &:hover,
            &:active {
                color: $black;
                background-color: $gray-ghost;
            }
        }
    }
}
