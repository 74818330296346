.ProductTitle {
    p {
        font-family: var(--tertiary-font);
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        line-height: 1.2;

        font-size: calc(1.325rem + 0.9vw);
    }

    @include media-breakpoint-down(md) {
        p {
            font-size: calc(1.325rem + 0.9vw);
        }
    }
}
