﻿@import '../../../../../Common/src/scss/components/react/ProductTabContent.scss';

.ProductTabContent {
	.tab-content {
        margin-top: 27px;
    }

	li .nav-link.active {
		font-weight: 800 !important;
	}

	@include media-breakpoint-down(sm) {
		padding-top: 56px;
    	padding-bottom: 61px;
	}
}
