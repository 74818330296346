﻿@import '../../../../Common/src/scss/pages/_commonnode.scss';

.catalog-node {
    ul.nav-tabs {
        > li {
            a.nav-link {
                color: var(--primary);
            }
        }
    }

    .category-node {
        min-height: 202px;
        border: none;
        border-radius: 0;
        background-color: $black;

        &:hover {
            background-color: $black;

            .category-node__background {
                opacity: 0.4;
            }
        }

        .category-node__background {
            opacity: 1;
            transition: opacity 200ms ease-out;
            z-index: 1;
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        & > a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 0 0%;
            padding: 2.5rem 1.5rem;
            text-decoration: none;
            color: $white;

            .category-node__title {
                @include font-size(16px);
            }
        }

        & > * {
            z-index: 2;
        }
    }
}
