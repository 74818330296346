﻿@import '../../../Common/src/scss/pages/_catalog_product.scss';

.catalog__product {
    margin-bottom: 0 !important;
    .breadcrumb {
        justify-content: left !important;
    }
    .breadcrumb-item {
        font-family: var(--tertiary-font);
        font-weight: bold;
        @include font-size(16px);
        letter-spacing: 0.1px;
    }
}
