@import '../../../../../Common/src/scss/components/react/LanguageSelector.scss';

.LanguageSelector {
    button {
        &.dropdown-toggle {
            border: none;
            color: $light;
        }
    }

    &.account-settings {
        button {
            &.dropdown-toggle {
                border: none;
                color: $secondary-dark !important;
            }
        }
    }
}
