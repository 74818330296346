﻿@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.CatalogCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    cursor: pointer;
    background: white;
    animation: fadeIn 0.3s ease-out;

    @media (hover: hover) {
        &:hover {
            text-decoration: none;
        }
    }

    img {
        max-width: calc(100% - 2em);
        height: auto;
        margin-bottom: 0.75rem;
        width: 430px;
        height: 430px;
        background-color: $white;
        object-fit: contain;
    }

    &__flag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0.25em 0.5em;
        margin-bottom: 1em;

        &.primary {
            background: var(--primary);
        }

        &.secondary {
            background: $gray-ghost;
        }

        > span {
            @include font-size(14px);
            font-family: var(--primary-font);
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .subheader {
        color: $black !important;
        text-align: center;
    }

    .ProductBadge {
        width: 100%;
    }

    &__prices {
        color: $dark;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5em;

        > div {
            @include font-size(19px);
            font-family: var(--tertiary-font);
            font-weight: bold;

            @include media-breakpoint-down(sm) {
                font-size: 1rem !important;
            }
        }
    }

    &__price.strike {
        text-decoration: line-through;
        font-weight: normal;
    }

    &__reduced-price {
        color: $red !important;
    }

    &__links {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
        margin-top: 1em;

        > li {
            display: block;

            div {
                cursor: pointer;
                display: flex;
                align-items: center;

                > i {
                    margin-right: 0.5em;
                    font-size: 0.75em;
                }

                > span {
                    font-family: var(--tertiary-font);

                    @include font-size(16px);

                    @include media-breakpoint-down(sm) {
                        font-size: 0.8rem;
                    }

                    font-weight: bold;
                    letter-spacing: 0.25px;

                    @media (hover: hover) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }

            &:not(:first-of-type) {
                margin-left: 1em;

                @include media-breakpoint-down(md) {
                    margin-left: 0px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        img {
            height: 186px;
            pointer-events: none;
        }

        &__links {
            display: flex;
            justify-content: center;
        }

        .subheader {
            text-align: center;
        }
    }
}
