﻿@import '../../../../Common/src/scss/components/_navigation_main.scss';

.navigation__main {
    height: 100%;
    background-color: var(--primary);

    .navbar-nav {
        height: 100%;

        .nav-item {
            height: 100%;
            position: relative;

            .nav-link {
                @include font-size(14px);
                color: $white;

                .nav-link__content {
                    border-bottom: 2px var(--primary) solid;
                    transition: padding-bottom 0.25s;
                }
            }

            .nav-item__children-panel {
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                background-color: $white;
                z-index: $zindex-popover;

                .row {
                    margin: 0;
                }

                .nav-item__children-list {
                    list-style-type: none;
                    padding-left: 0;

                    li {
                        a {
                            font-size: 18px;
                            font-weight: 500;
                            text-transform: uppercase;
                            &:hover {
                                text-decoration: none;
                                background-color: $gray-medium;
                            }
                        }
                    }
                }
            }

            &.active,
            &:hover {
                .nav-link {
                    background-color: transparent;

                    .nav-link__content {
                        border-bottom: 2px $preston-green solid;
                    }

                    &::after {
                        content: ' ';
                        background-color: var(--primary);
                        width: 50%;
                        left: 25%;
                        bottom: 8px;
                        position: absolute;
                        height: 1px;
                    }
                }
            }

            &:hover {
                .nav-item__children-panel {
                    display: block;
                }
            }
        }
    }
}

.navbar-toggler {
    color: $white;
    z-index: 1;
}

.navigation__mobile {
    background-color: var(--primary);
    position: fixed;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    z-index: $zindex-modal;
    transition: left 0.5s;
    padding: 60px 40px 40px 40px;
    overflow-y: auto;

    .navigation__mobile-container {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .navigation__mobile-nav {
        border-bottom: 2px solid $preston-green;
    }

    &.show {
        left: 0;
    }

    .header__social {
        text-align: center;
    }

    .navigation__mobile-collapse-button {
        border: none;
        background-color: transparent;
        color: $white;
        @include font-size(36px);
    }

    .navigation__mobile-list {
        list-style-type: none;
        padding-left: 0;

        li {
            margin: 1rem 0;

            a {
                font-size: calc(1.25625rem + 0.075vw);
                text-transform: uppercase;
                color: $white;
                font-weight: 500;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.navigation__mobile__child {
    z-index: $zindex-modal + 1;

    .navigation__mobile__child-container {
        height: 100%;
        overflow-y: auto;
        border-bottom: 2px solid $preston-green;

        .divider {
            height: 2px;
            background: $preston-green;
            margin: 20px 0px;
        }

        .navigation__mobile-child-header {
            @include font-size(28px);
            text-transform: uppercase;
            border-bottom: 2px solid $preston-green;
        }

        .navigation__mobile-return-home {
            button {
                @include font-size(14px);
                text-transform: none;
                background-color: transparent;
                border: none;
                color: $white;
                i {
                    color: $preston-green;
                }
            }
        }

        .navigation__mobile-list {
            list-style-type: none;
            padding-left: 0;

            li {
                margin: 0;

                a {
                    @include font-size(16px);
                    text-transform: none;
                    color: $white;
                    font-weight: 500;
                    display: block;
                    padding: 0.25rem 0;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
