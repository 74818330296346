﻿@import '../../../../../Common/src/scss/components/react/FilterDropdown.scss';

.FilterDropdown {
    button {
        &:hover {
            color: $gray-dark;
        }
    }

    &.expanded {
        background-color: $gray-light;

        button {
            > span {
                color: $gray-dark;
            }
        }
    }

    .FilterDropdown__button-list {
        background-color: $gray-light;

        button {
            width: 100%;

            &.selected {
                background-color: var(--primary);
                > span {
                    color: $white;
                }
            }

            > span {
                text-decoration: none !important;
            }
        }

        label {
            > span {
                color: $gray-dark;
            }
        }
    }
}
