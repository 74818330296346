﻿.navigation__list {
    & .dropdown-item {
        font-family: var(--secondary-font);
        @include font-size(14px);
        letter-spacing: 2px;
        color: $dark;
        flex: 1 1 10px;
        padding: 0.5rem 1.5rem;

        &:hover,
        &:focus {
            background-color: $white;
            color: $secondary-blue;
        }

        &:first-child {
            background-color: var(--primary);
            color: $white;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}
