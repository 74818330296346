﻿@import '../../../../../Common/src/scss/components/react/ShopByDropdown.scss';

.ShopByDropdown {
    .ShopByDropdown__Values {
        > div {
            > label {
                input[type='checkbox'] {
                    border-color: $black;
                }
            }
        }
    }
}

.MobileShopBy.MobileFiltersMenu {
    .MobileFiltersMenu__list {
        label {
            div.input-checkbox {
                border-color: $black;
            }
        }
    }
}
