﻿.btn,
.btn-lg,
.btn-sm {
    text-transform: uppercase;
    font-family: var(--secondary-font);
    font-weight: normal;
}

.btn:hover,
.btn-lg:hover,
.btn-sm:hover {
    opacity: 0.9;
}

.btn:active,
.btn-lg:active,
.btn-sm:active {
    opacity: 0.8;
}

.btn-primary:focus {
    border: 2px solid var(--primary);
}

.btn-secondary:focus {
    border: 2px solid var(--secondary);
}

.btn-primary-no-border,
.btn-primary-no-border:hover,
.btn-primary-no-border:active,
.btn-primary-no-border:focus {
    background-color: var(--primary);
    color: $white;
    border: none !important;
}

.btn {
    letter-spacing: 2px;
    @include font-size(19px);
    padding: 16px 40px;
}

.btn-lg {
    letter-spacing: 1.92px;
    line-height: $btn-line-height-lg;
    @include font-size(23);
    padding: 20px 50px;
}

.btn-sm {
    letter-spacing: 1.5px;
    line-height: 16px;
    @include font-size(16);
    padding: 7px 28px;
}

.btn,
.btn-lg,
.btn-sm,
.btn:focus,
.btn-lg:focus,
.btn-sm:focus,
.btn:hover,
.btn-lg:hover,
.btn-sm:hover,
.btn:active,
.btn-lg:active,
.btn-sm:active {
    &.disabled {
        @extend .disabled;
    }
}

button:disabled {
    pointer-events: none;
}
