﻿@import '../../../../Common/src/scss/sections/_header.scss';

.header__banner {
    text-align: center !important;
    color: $white;
    @include font-size(14);
    background: var(--primary);
    text-align: center;

    @media (hover: hover) {
        &:hover {
            color: $white;
        }
    }
}

.header__section {
    background-color: var(--primary);

    .header__user button,
    a.header__login {
        @extend .paragraph-3;
        font-family: var(--tertiary-font);
    }

    .header_user {
        button {
            i.fa-chevron-down {
                font-size: 14px;
            }
        }
    }

    .search {
        font-family: var(--tertiary-font);
        letter-spacing: 0.25px;
        text-transform: none !important;

        span {
            pointer-events: none;
        }
    }

    @include media-breakpoint-up(md) {
        .search {
            @include font-size(14);
        }
    }

    @include media-breakpoint-down(md) {
        padding: 7px 0px;
    }

    .container {
        @include media-breakpoint-down(sm) {
            padding: 7px 0px;
        }
    }
}

.header__social {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;

    li {
        display: inline-block;

        a {
            color: $white;
            @include font-size(14px);
            background-color: #3b3b3b;
            border-radius: 50%;
            height: 32px;
            width: 32px;

            .icon-tiktokicon {
                height: 22px !important;
                width: 22px !important;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.header__my-account-container {
    color: $white;
    height: 100%;

    button.header__minicart {
        background: transparent;
        padding-left: 15px;
        border: none;
    }
}

.header__usermenu {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.header__logo {
    position: relative;
    z-index: 1;
}

@include media-breakpoint-down(lg) {
    .header__logo {
        height: 24px;
    }
}
